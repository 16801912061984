<template>
  <section>
    <title-section
      title="Contacto"
    />
    <contact-main />
  </section>
</template>

<script>
export default {
  name: 'Contact',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    ContactMain: () => import('@/components/Contact/ContactMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
